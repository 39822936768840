@use "breakpoints.scss";

.badgeRow {
  position: relative;
  overflow: hidden;
}

.content {
  display: inline;

}

.expand {
  display: flex;
  position: absolute;
  bottom: 1px;
  width: auto;
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
  .badgeRow .expand {
    display: none;
  }

  .badgeRow:hover .expand {
    display: inline;
  }
}